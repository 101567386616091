import React, { useMemo } from 'react'
import Card from '../../../components/common/Card'
import { EnumSafeGoldDataType, Transaction } from '../../../services/transaction/transaction-typed'
import Table from '../../../components/common/Table'
import dayjs from 'dayjs'
import { pascalize } from 'humps'
import { replaceUnderScoreAndCapitalize } from '../../../../utils/helper'
import { Log } from '../../../services/kyc/kyc-typed'
import Text from '../../../components/common/Text'

type LogCardProps = {
  logs: Log[]
}
export enum SafeGoldTransactionStatus {
  NOT_CONFIRMED = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export enum EnumDeliveryDispatchStatus {
  PACKAGE_PENDING = 1,
  PACKED = 2,
  DISPATCHED = 3,
  DELIVERED = 4,
  FAILED = 5,
}
export enum EnumSettlementStatus {
  SETTLED = 1,
  FAILED = 2,
}

export const LogCard = (props: LogCardProps) => {
  const { logs } = props

  const data = useMemo(() => {
    return logs.map(log => {
      const { data, createdAt } = log

      return {
        createdAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        data: JSON.stringify(data, null, 2),
      }
    })
  }, [logs])

  const columns = useMemo(() => {
    return (
      data.length &&
      Object.keys(data[0]).map(key => {
        return {
          title: pascalize(key),
          dataIndex: key,
        }
      })
    )
  }, [data])
  return columns ? (
    <Card style={{ marginTop: 10 }}>
      <Text variant="h5">{'Log'}</Text>
      <Table style={{ marginTop: 10 }} columns={columns} data={data} />
    </Card>
  ) : (
    <></>
  )
}

export default LogCard
