import React, { PropsWithChildren } from 'react'
import { useCurrentUser } from '../services/user/user-query'
import { ROLE } from '../services/user/user-typed'

interface IAuthorizeProps {
  role: ROLE
}
const roleLevels: ROLE[] = [ROLE.USER, ROLE.VIEWER, ROLE.MANAGER, ROLE.ADMIN, ROLE.SUPER_ADMIN]
export const validateCorrectRole = (role: ROLE, userRole?: ROLE) => {
  if (!role) return true
  if (!userRole) return false
  const targetRoleIndex = roleLevels.indexOf(role)
  const userRoleIndex = roleLevels.indexOf(userRole)
  return userRoleIndex >= targetRoleIndex
}

export const Authorize = (props: PropsWithChildren<IAuthorizeProps>) => {
  const { data: currentUser } = useCurrentUser()
  if (validateCorrectRole(props.role, currentUser?.role)) {
    return <>{props.children}</>
  }
  return <></>
}
