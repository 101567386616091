import React, { useCallback, useMemo, useState } from 'react'
import Card from '../../../components/common/Card'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import Input from '../../../components/common/Input'
import Button from '../../../components/common/Button'
import {
  useDecreaseSellLimit,
  useGetCurrentSellLimit,
  useIncreaseSellLimit,
} from '../../../services/setting/setting-query'
import { numberWithCommas } from '../../../../utils/core'
import { NumericFormat, NumberFormatValues } from 'react-number-format'

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 8px;
`
const SquareButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  margin-top: 8px;
`

const Setting = () => {
  const { data: sellLimit = 0, isLoading: isLoadingSellLimit } = useGetCurrentSellLimit()
  const { mutateAsync: increaseSellLimit, isLoading: isLoadingIncrease } = useIncreaseSellLimit()
  const { mutateAsync: decreaseSellLimit, isLoading: isLoadingDecrease } = useDecreaseSellLimit()

  const [increaseValue, setIncreaseValue] = useState<number>()
  const [decreaseValue, setDecreaseValue] = useState<number>()
  const handleChangeIncreaseValue = useCallback((e: NumberFormatValues) => {
    setIncreaseValue(e.floatValue)
  }, [])
  const handleChangeDecreaseValue = useCallback((e: NumberFormatValues) => {
    setDecreaseValue(e.floatValue)
  }, [])
  const handleClickIncrease = useCallback(async () => {
    try {
      await increaseSellLimit(increaseValue ?? 0)
    } catch (error) {
      console.log(error)
    }
  }, [increaseValue, increaseSellLimit])
  const handleClickDecrease = useCallback(async () => {
    try {
      await decreaseSellLimit(decreaseValue ?? 0)
    } catch (error) {
      console.log(error)
    }
  }, [decreaseValue, decreaseSellLimit])

  const isLoading = useMemo(() => {
    return isLoadingSellLimit || isLoadingIncrease || isLoadingDecrease
  }, [isLoadingDecrease, isLoadingIncrease, isLoadingSellLimit])

  return (
    <Authorize role={ROLE.ADMIN}>
      <Card>
        <ContentLayout>
          <Text>Current Limit: {numberWithCommas(sellLimit)}</Text>
          <div />
          <NumericFormat
            placeholder="Increase Value"
            value={increaseValue}
            onValueChange={handleChangeIncreaseValue}
            thousandSeparator
            allowNegative={false}
            customInput={Input}
            isAllowed={value => (value.floatValue || 0) <= Number.MAX_SAFE_INTEGER}
          />
          <SquareButton onClick={handleClickIncrease} disabled={!increaseValue || isLoading}>
            +
          </SquareButton>
          <NumericFormat
            placeholder="Decrease Value"
            value={decreaseValue}
            onValueChange={handleChangeDecreaseValue}
            thousandSeparator
            allowNegative={false}
            isAllowed={value => (value.floatValue || 0) <= sellLimit}
            customInput={Input}
          />
          <SquareButton onClick={handleClickDecrease} disabled={!decreaseValue || isLoading}>
            -
          </SquareButton>
        </ContentLayout>
      </Card>
    </Authorize>
  )
}

export default Setting
