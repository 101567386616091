import numeral from 'numeral'
import React, { useMemo } from 'react'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import PortalCard, { IPortalCardContentData } from '../../../components/templates/PortalCard'
import {
  getColorPaymentStatus,
  isDevelopment,
  replaceUnderScoreAndCapitalize,
} from '../../../../utils/helper'
import { Payment } from '../../../services/transaction/transaction-typed'
import dayjs from 'dayjs'
import { compact } from 'lodash'

interface PaymentCardProps {
  payment: Payment
}
const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

export const PaymentCard = (props: PaymentCardProps) => {
  const { payment } = props
  const {
    id,
    amount = '',
    type = '',
    status = '',
    referenceOrder,
    failureMessage = '',
    failOddLog,
    createdAt,
    updatedAt,
    tmnIsvRefundRef,
    tmnPaymentId,
    tmnRefundId,
  } = payment || {}

  const checkErrorMessage = useMemo(() => {
    if (type === 'promptpay_no') {
      return failureMessage
    } else if (type === 'online_direct_debit') {
      return failOddLog.transactionList ? failOddLog.transactionList[0].returnMessage : '-'
    } else {
      return '-'
    }
  }, [failOddLog.transactionList, failureMessage, type])
  const dataPayment = useMemo(() => {
    const data: IPortalCardContentData[] = compact([
      isDevelopment ? { label: 'Id', value: id } : undefined,
      isDevelopment
        ? { label: 'createdAt', value: dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss') }
        : undefined,
      isDevelopment
        ? { label: 'updatedAt', value: dayjs(updatedAt).format('DD/MM/YYYY HH:mm:ss') }
        : undefined,
      { label: 'Amount', value: `${numeral(amount).format('0,0.00')} THB` },
      {
        label: 'ReferenceOrder',
        value: referenceOrder || '-',
      },
      { label: 'type.', value: type || '-' },
      { label: 'tmnPaymentId', value: tmnPaymentId || '-' },
      { label: 'tmnIsvRefundRef', value: tmnIsvRefundRef || '-' },
      { label: 'tmnRefundId', value: tmnRefundId || '-' },
    ])
    const errorsMessage = { label: 'Message error', value: checkErrorMessage }
    if (status === 'failed') {
      data.push(errorsMessage)
    }
    return compact(data)
  }, [
    id,
    createdAt,
    updatedAt,
    amount,
    referenceOrder,
    type,
    tmnPaymentId,
    tmnIsvRefundRef,
    tmnRefundId,
    checkErrorMessage,
    status,
  ])

  return (
    <PortalCard
      title={
        <Flex>
          <Text bold variant="h5">
            Payment
          </Text>
          <TextColor variant="h5" color={getColorPaymentStatus(status)}>
            ({replaceUnderScoreAndCapitalize(status)})
          </TextColor>
        </Flex>
      }
      contents={dataPayment}
    />
  )
}

export default PaymentCard
