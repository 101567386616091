import { useQuery, useQueryClient, useMutation } from 'react-query'
import { IGetSettingDetailResponse, IPatchSettingDetailBodyParams } from './setting-type'
import { api } from '../../../utils/api'

export const BASE_SETTING_URL = 'internal/setting'
export const BASE_SERVER_MAINTENANCE_URL = `${BASE_SETTING_URL}/maintenance`
export const GET_SELL_LIMIT_URL = `${BASE_SETTING_URL}/sell-limit`
export const INCREASE_SELL_LIMIT_URL = `${BASE_SETTING_URL}/increase-sell-limit`
export const DECREASE_SELL_LIMIT_URL = `${BASE_SETTING_URL}/decrease-sell-limit`

export const useGetSettingDetail = () => {
  return useQuery([BASE_SETTING_URL], async () => {
    const response = await api.savvyGold.get<IGetSettingDetailResponse>(BASE_SERVER_MAINTENANCE_URL)
    return response.data.data
  })
}

export const usePatchSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IPatchSettingDetailBodyParams) => {
      const { data } = await api.savvyGold.patch(`${BASE_SERVER_MAINTENANCE_URL}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_SETTING_URL])
      },
    },
  )
}

export const useGetCurrentSellLimit = () => {
  return useQuery([GET_SELL_LIMIT_URL], async () => {
    const response = await api.savvyGold.get<number>(GET_SELL_LIMIT_URL)
    return response.data.data
  })
}

export const useIncreaseSellLimit = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (value: number) => {
      const response = await api.savvyGold.patch<number>(INCREASE_SELL_LIMIT_URL, {
        value,
      })
      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SELL_LIMIT_URL])
      },
    },
  )
}

export const useDecreaseSellLimit = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (value: number) => {
      const response = await api.savvyGold.patch<number>(DECREASE_SELL_LIMIT_URL, {
        value,
      })
      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SELL_LIMIT_URL])
      },
    },
  )
}
