import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetKyc, useUnblockPortDiff } from '../../../services/kyc/kyc-query'
import {
  customAddressLabel,
  getColorStatusKycText,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import { UserCard } from '../../user/UserProfile/UserCard'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import ImageViewer from '../../../components/common/ImageViewer'
import { useGetImageUser } from '../../../services/user/user-query'
import dayjs from 'dayjs'
import { Address } from '../../../services/port/port-typed'
import DetailPage2, { ContentType } from '../../../components/templates/DetailPage2'
import KycOnPendingAction from './KycOnPendingAction'
import { Box } from '@material-ui/core'
import { EKycState, EnumKycRejectedBy } from '../../../services/kyc/kyc-typed'
import RiskAssessmentDetail from './RiskAssessmentDetail'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { isEmpty } from 'lodash'
import Button from '../../../components/common/Button'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import { appConfig } from '../../../constants/app-config'
import SafeGoldCallBacksCard from '../../transaction/TransactionDetail/SafeGoldCallBacksCard'
import { useGetTransaction } from '../../../services/transaction/transaction-query'
import LogCard from './LogCard'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`
const RejectedReasonLayout = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 10px;
  border-top: 1px solid var(--secondary);

  margin-top: 16px;
  padding-top: 16px;

  .title {
    grid-column: 1 / span 2;
  }
`

const TitleLayout = styled(Box)`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
`

export const Flex = styled(Grid)`
  display: flex;
  margin: 0px;
`

export const KycDetail = () => {
  const { kycId } = useRouter().query
  const { data: kyc } = useGetKyc(kycId)
  const {
    ekycStatus = '',
    titleEn = '',
    firstnameEn = '',
    lastnameEn = '',
    citizenId = '',
    laserCode = '',
    gender = '',
    titleTh = '',
    firstnameTh = '',
    lastnameTh = '',
    birthdate = '',
    job = '',
    email = '',
    dopa,
    amlo,
    registerError,
    rejectReason,
    attachments = [],
  } = kyc || {}
  const [fontCitizenId, setFontCitizenId] = useState('')
  const [backCitizenId, setBackCitizenId] = useState('')
  const [holdCitizenId, setHoldCitizenId] = useState('')

  useEffect(() => {
    attachments.forEach(({ type, id: imageId }) => {
      if (type === 'holdCitizen') {
        setHoldCitizenId(imageId)
      } else if (type === 'citizen') {
        setFontCitizenId(imageId)
      } else if (type === 'laserCode') {
        setBackCitizenId(imageId)
      }
    })
  }, [attachments])

  const { data: imageCitizen } = useGetImageUser(fontCitizenId, 'origin')
  const { image: imageCitizenPhoto } = imageCitizen! || '-'
  const { data: imageUser } = useGetImageUser(backCitizenId, 'origin')
  const { image: imageUserPhoto } = imageUser! || '-'
  const { data: imageHoldCitizen } = useGetImageUser(holdCitizenId, 'origin')
  const { image: imageHoldCitizenPhoto } = imageHoldCitizen! || '-'

  const someImageMissing = useMemo(() => {
    return !imageCitizenPhoto || !imageUserPhoto || !imageHoldCitizenPhoto
  }, [imageCitizenPhoto, imageHoldCitizenPhoto, imageUserPhoto])

  const address = useMemo(() => {
    return kyc?.addresses && kyc?.addresses.length
      ? kyc?.addresses?.reduce((acc: Partial<Record<Address['type'], Address>>, data) => {
          acc[data.type] = data
          return acc
        }, {})
      : {}
  }, [kyc])

  const dataKyc = useMemo(() => {
    const data: ContentType[] = [
      { label: 'Title EN', value: titleEn ? titleEn : '-', position: 'left' },
      { label: 'Firstname EN', value: firstnameEn ? firstnameEn : '-', position: 'left' },
      { label: 'Lastname EN', value: lastnameEn ? lastnameEn : '-', position: 'left' },
      { label: 'Citizen Id', value: citizenId ? citizenId : '-', position: 'left' },
      { label: 'Laser code', value: laserCode ? laserCode : '-', position: 'left' },
      { label: 'Gender', value: gender ? gender : '-', position: 'left' },
      { label: 'Title TH', value: titleTh ? titleTh : '-', position: 'right' },
      { label: 'Firstname TH', value: firstnameTh ? firstnameTh : '-', position: 'right' },
      { label: 'Lastname TH', value: lastnameTh ? lastnameTh : '-', position: 'right' },
      {
        label: 'Birthdate',
        value: birthdate ? dayjs(birthdate).format('DD-MM-YYYY') : '-',
        position: 'right',
      },
      { label: 'Job', value: job ? job : '-', position: 'right' },
      { label: 'Email', value: email ? email : '-', position: 'right' },
    ]
    if (address.citizen) {
      data.push({
        label: 'Registered Address',
        colSpan: 2,
        value: customAddressLabel(address.citizen),
      })
    }
    if (address.recent) {
      data.push({
        label: 'Present Address',
        colSpan: 2,
        value: customAddressLabel(address.recent),
      })
    }
    if (address.work) {
      data.push({
        label: 'Work Address ',
        colSpan: 2,
        value: customAddressLabel(address.work),
      })
    }
    // data.push({
    //   label: 'Attachments',
    //   value: someImageMissing ? (
    //     <Text bold>-</Text>
    //   ) : (
    //     <ImageViewer images={[imageCitizenPhoto, imageUserPhoto, imageHoldCitizenPhoto]} />
    //   ),
    // })
    if (kyc?.riskAssessments) {
      data.push({
        label: 'Risk Assessment',
        value: <RiskAssessmentDetail riskAssessments={kyc.riskAssessments} />,
      })
    }
    return data
  }, [
    address.citizen,
    address.recent,
    address.work,
    birthdate,
    citizenId,
    email,
    firstnameEn,
    firstnameTh,
    gender,
    job,
    kyc,
    laserCode,
    lastnameEn,
    lastnameTh,
    titleEn,
    titleTh,
  ])

  const dateRejectReasons = useMemo(() => {
    const data: ContentType[] = []
    if (registerError) data.push({ label: 'RegisterError', value: registerError })
    if (rejectReason) data.push({ label: 'RejectReason', value: rejectReason })
    if (dopa && dopa.isError === 'true') data.push({ label: 'Dopa', value: dopa.desc })
    if (amlo && amlo.status && amlo.status !== 'pass')
      data.push({ label: 'Amlo', value: amlo.data })
    return data
  }, [amlo, dopa, registerError, rejectReason])

  const { mutate: unblockPortDiff } = useUnblockPortDiff()
  const toast = useToastForReactQuery()

  const isEmptyRiskAssessment = useMemo(() => isEmpty(kyc?.riskAssessments), [kyc])
  const onClickUnblockPortDiff = useCallback(() => {
    if (kyc) {
      unblockPortDiff(kyc.id, {
        onSuccess: () => {
          toast.open('Success', {
            type: 'success',
            autoClose: 3 * 1000,
          })
        },
        onError: error => {
          toast.open('error', {
            type: 'error',
            autoClose: 3 * 1000,
          })
        },
      })
    }
  }, [kyc, toast, unblockPortDiff])

  const isPortDiff = useMemo(() => {
    return kyc?.rejectedBy === EnumKycRejectedBy.REJECT_BY_PORT_DIFF
  }, [kyc])
  return kyc ? (
    <>
      <DetailPage2
        title={
          <TitleLayout>
            <Text variant="h5" bold style={{ color: getColorStatusKycText(ekycStatus) }}>
              {replaceUnderScoreAndCapitalize(ekycStatus)}
            </Text>
            <Authorize role={ROLE.ADMIN}>
              {isPortDiff && appConfig.ENABLE_FEATURE_TRUE_MONEY && (
                <Button onClick={onClickUnblockPortDiff}>Unblock Port Diff</Button>
              )}
            </Authorize>
            {ekycStatus === EKycState.PENDING && !isEmptyRiskAssessment && (
              <Authorize role={ROLE.ADMIN}>
                <KycOnPendingAction kycId={kycId} userId={kyc.userId} />
              </Authorize>
            )}
          </TitleLayout>
        }
        contents={dataKyc}
        gridItems={[<UserCard userId={kyc.userId} />]}
        extendLayout={
          dateRejectReasons.length ? (
            <RejectedReasonLayout>
              <div className="title">
                <Text bold variant={'h6'}>
                  Reject Reason
                </Text>
              </div>
              {dateRejectReasons.map((dateRejectReason, index) => (
                <Fragment key={[dateRejectReason?.label, dateRejectReason?.value, index].join('-')}>
                  <Text>{dateRejectReason?.label}</Text>
                  <Text bold>{dateRejectReason?.value}</Text>
                </Fragment>
              ))}
            </RejectedReasonLayout>
          ) : (
            undefined
          )
        }
      />
      <LogCard logs={kyc.logs} /> : <></>
    </>
  ) : (
    <></>
  )
}
export default KycDetail
