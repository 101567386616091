import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { useGetGiveawayFiles, useUploadGiveaway } from '../../../services/giveaway/giveaway-query'
import { useHistory } from 'react-router-dom'
import {
  EnumGiveawayStatus,
  IGiveawayFile,
  IGiveawayFileList,
} from '../../../services/giveaway/giveaway-typed'
import { useDropzone } from 'react-dropzone'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import { appConfig } from '../../../constants/app-config'
import ModalCreateGiveaway from '../RewardList/ModalCreateGiveaway'
import ModalExportGiveaway from '../RewardList/ModalExportGiveaway'
import { Box } from '@material-ui/core'
import OTPModal from '../../../components/common/OTPModal'
import { useOtpMobileByToken } from '../../../services/otp/otp-query'
import { OtpAction } from '../../../services/otp/otp-types'
import { useTranslation } from 'react-i18next'
import { numberWithCommas } from '../../../../utils/core'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`

const SummaryLayout = styled.ul`
  padding-inline-start: 10px;

  > li {
    white-space: nowrap;
  }
`

const ButtonStyled = styled(Button)`
  padding: 7px 13px;
  margin-right: 16px;
`

const StatusLayout = styled(Box)`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 30px;

  > p {
    margin: 0;
  }
`

type OTPState = {
  id: string
  refCode: string
}

const RewardImport = () => {
  const { push, query } = useRouter()
  const history = useHistory()
  const { q, page } = query

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false)
  const [otpModalVisible, setOtpModalVisible] = useState(false)
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [otpState, setOtpState] = useState<OTPState | null>(null)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const { mutate: uploadFile } = useUploadGiveaway()
  const { mutateAsync: requestOtp } = useOtpMobileByToken()
  const { t } = useTranslation()

  const onCloseExportModal = useCallback(() => {
    setOpenExportModal(false)
  }, [])

  const onCloseCreateModal = useCallback(() => {
    setOpenCreateModal(false)
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'File Name',
        dataIndex: 'fileName',
      },
      {
        title: 'Create At',
        dataIndex: 'createdAt',
        render: (text: string) => {
          return <Text>{dayjs(text).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (text: string) => {
          return <Text>{dayjs(text).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Progress',
        dataIndex: 'summary',
        render: (_: unknown, record: IGiveawayFile) => {
          const { summary } = record
          return (
            <SummaryLayout
              style={{
                paddingInlineStart: '10px',
              }}
            >
              <li>Total: {numberWithCommas(summary.total, 0)}</li>
              <li>Pending: {numberWithCommas(summary.countPending, 0)}</li>
              <li>Processing: {numberWithCommas(summary.countProcessing, 0)}</li>
              <li>Success: {numberWithCommas(summary.countSuccess, 0)}</li>
              <li>Fail: {numberWithCommas(summary.countFail, 0)}</li>
            </SummaryLayout>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => {
          const color = (status: EnumGiveawayStatus) => {
            switch (status) {
              case EnumGiveawayStatus.FAIL:
                return '#ff0000'
              case EnumGiveawayStatus.SUCCESS:
                return '#00b050'
              case EnumGiveawayStatus.PENDING:
                return '#ffc000'
              default:
                return '#1E90FF'
            }
          }
          return (
            <StatusLayout color={color(status as EnumGiveawayStatus)}>
              <Text bold>{status}</Text>
            </StatusLayout>
          )
        },
      },
      {
        title: 'Error',
        dataIndex: 'errorMessage',
        render: (errorMessage: string) => {
          return (
            <Text
              bold
              style={{
                maxWidth: '200px',
                wordBreak: 'break-all',
              }}
            >
              {errorMessage}
            </Text>
          )
        },
      },
    ]
  }, [])

  const { data: giveawayFiles } = useGetGiveawayFiles({ q, page })

  const setQueryParam = useCallback(
    params => {
      push(paths.rewardCreate({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  const toast = useToastForReactQuery()

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]

        try {
          const otpResponse = await requestOtp({
            action: OtpAction.MANUAL_GIVE,
          })
          setOtpState({ id: otpResponse.otpId, refCode: otpResponse.refCode })
          setFileToUpload(file)
          setOtpModalVisible(true)
        } catch (error) {
          toast.open(t(error), {
            type: 'error',
            autoClose: 3 * 1000,
          })
        }
      }
    },
    [requestOtp, t, toast],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    multiple: false,
  })

  const handleOtpConfirm = useCallback(
    (otp: string) => {
      const body = { file: fileToUpload, otpData: { otp, otpId: otpState?.id } }

      if (otpState && fileToUpload) {
        uploadFile(body, {
          onError: error => {
            if (error === '422-022') {
              setOtpErrorMessage(t(error))
            } else {
              toast.open('Can not create give away', {
                type: 'error',
                autoClose: 3 * 1000,
              })
              setOtpModalVisible(false)
            }
          },
          onSuccess: () => {
            toast.open('Success', {
              type: 'success',
              autoClose: 3 * 1000,
            })
            setOtpErrorMessage('')
            setOtpModalVisible(false)
          },
        })
      }
    },
    [fileToUpload, otpState, uploadFile, t, toast],
  )

  return (
    <ListPage
      topbar={
        appConfig.ENABLE_FEATURE_GIVEAWAY ? (
          <Authorize role={ROLE.SUPER_ADMIN}>
            <Layout>
              <ButtonStyled {...getRootProps({ className: 'dropzone' })} variant="outline-primary">
                import
                <input {...getInputProps()} />
              </ButtonStyled>
              <ModalCreateGiveaway visible={openCreateModal} onClose={onCloseCreateModal} />
              <ModalExportGiveaway visible={openExportModal} onClose={onCloseExportModal} />
              <OTPModal
                show={otpModalVisible}
                handleClose={() => {
                  setOtpErrorMessage('')
                  setOtpModalVisible(false)
                }}
                handleConfirm={handleOtpConfirm}
                otpRef={otpState?.refCode}
                errorMessage={otpErrorMessage}
              />
            </Layout>
          </Authorize>
        ) : (
          undefined
        )
      }
      tableProps={{
        columns,
        data: giveawayFiles?.items || [],
        onPageChange,
        pagination: giveawayFiles?.meta,
      }}
      searchInputProps={{ onSearch, value: query.q, placeholder: 'Search' }}
    />
  )
}

export default RewardImport
