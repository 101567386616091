export type Otp = {
  otpId: string
  refCode: string
  isVerify: boolean
}

export enum OtpAction {
  REGISTER_EMAIL = 'register-email',
  REGISTER_MOBILE = 'register-mobile',
  FORGOT_PASSWORD = 'forgot-password',
  FORGOT_PIN = 'forgot-pin',
  UPDATE_EMAIL_FACEBOOK = 'update-email-facebook',
  OPEN_PORT = 'open-port',
  CHANGE_ADDRESS_IN_MARKETPLACE = 'change-address-in-marketplace',
  CHANGE_ADDRESS_IN_PROFILE = 'change-address-in-profile',
  INTERNAL_LOGIN = 'internal-login',
  MANUAL_GIVE = 'manual-give',
  MANUAL_SELL = 'manual-sell',
}

export type CreateOTPMobileByEmailBoDto = {
  value?: string
  action?: OtpAction
}

export type CreateOTPMobileByTokenBoDto = {
  action?: OtpAction
}
