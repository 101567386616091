import { useMutation, useQuery } from 'react-query'
import { api } from '../../../utils/api'
import {
  EnumTransactionType,
  ExportTransactionReportParams,
  GetProductsResponseType,
  GoldProductType,
  Transaction,
  TransactionList,
} from './transaction-typed'
import { downloadFile } from '../../../utils/helper'
import dayjs from 'dayjs'

export const TRANSACTION_URL = `internal/transactions`
export const TRANSACTION_REPORT_URL = `${TRANSACTION_URL}/report`
export const BASE_SELL_URL = 'sell'
export const GET_GOLD_PRODUCTS_URL = `${BASE_SELL_URL}/products`

const customTransactionData = (transaction: Transaction) => {
  return transaction
}

export const useGetTransactions = (params?: { q?: string; page?: number; portId?: string }) => {
  return useQuery(
    [TRANSACTION_URL, params],
    async () => {
      const { data } = await api.savvyGold.get<TransactionList>(TRANSACTION_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
        portId: params?.portId,
      })
      const result = data.data
      result.items = result.items.map(v => customTransactionData(v))
      return result
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetTransaction = (transactionId: string) => {
  return useQuery(
    [TRANSACTION_URL, transactionId],
    async () => {
      const { data } = await api.savvyGold.get<Transaction>(`${TRANSACTION_URL}/${transactionId}`)
      return customTransactionData(data.data)
    },
    {
      enabled: !!transactionId,
    },
  )
}

export const useGetTransactionByPortId = (portId: string) => {
  return useQuery(
    [TRANSACTION_URL, portId],
    async () => {
      const { data } = await api.savvyGold.get<Transaction>(`${TRANSACTION_URL}/port/${portId}`)
      return customTransactionData(data.data)
    },
    {
      enabled: !!portId,
    },
  )
}

export const useGetAllTransactionsByPortId = (
  portId: string,
  params?: {
    q?: string
    page?: number
    type?: EnumTransactionType
    limit?: number
    isManual?: boolean
  },
) => {
  return useQuery(
    [TRANSACTION_URL, portId, params],
    async () => {
      const { data } = await api.savvyGold.get<TransactionList>(
        `${TRANSACTION_URL}/port/${portId}`,
        {
          page: params?.page,
          limit: params?.limit || 10,
          q: params?.q,
          type: params?.type,
          isManual: params?.isManual,
        },
      )
      const result = data.data
      result.items = result.items.map(v => customTransactionData(v))
      return result
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useExportTransactionReport = () => {
  return useMutation<any, string, ExportTransactionReportParams>(
    async (params: ExportTransactionReportParams) => {
      const { startDate, endDate, ...restParams } = params
      const res = await api.savvyGold.getFile(`${TRANSACTION_REPORT_URL}`, {
        startDate: dayjs(startDate).format(),
        endDate: dayjs(endDate).format(),
        ...restParams,
      })
      downloadFile(res)
    },
  )
}

export const customGoldProduct = (product: GoldProductType) => {
  const { name, goldPattern, ...restProduct } = product
  return {
    ...restProduct,
    goldPattern: goldPattern,
    pattern: goldPattern === 'ลายโลโก้ออสสิริส' ? 'ลายมาตรฐาน' : goldPattern,
    name: name.replace('บาท', 'บาททอง'),
  } as const
}

export const useGetGoldProductDetail = (productId: number) => {
  return useQuery(
    [GET_GOLD_PRODUCTS_URL, productId],
    async () => {
      const { data } = await api.savvyGold.get<GetProductsResponseType>(GET_GOLD_PRODUCTS_URL, {})
      const product = data.data.products.find(product => product.id === productId)
      return product ? customGoldProduct(product) : undefined
    },
    {
      cacheTime: 1 * 60 * 1000,
      staleTime: 0,
    },
  )
}

export const useGetGoldProductDetailById = (productId: string) => {
  return useQuery(
    [GET_GOLD_PRODUCTS_URL, productId],
    async () => {
      const { data } = await api.savvyGold.get<GetProductsResponseType>(GET_GOLD_PRODUCTS_URL, {
        productId: productId,
      })
      return data
    },
    {
      cacheTime: 1 * 60 * 1000,
      staleTime: 0,
    },
  )
}
