import React, { useEffect, useMemo, useState } from 'react'
import { Typography } from '@material-ui/core'
import styled from '@emotion/styled'
import { customAddressLabel, useRouter } from '../../../../utils/helper'
import { useGetPort } from '../../../services/port/port-query'
import DetailPage from '../../../components/templates/DetailPage'
import numeral from 'numeral'
import UserCard from '../../user/UserProfile/UserCard'
import { Address } from '../../../services/port/port-typed'
import { useGetImageUser } from '../../../services/user/user-query'
import ImageViewer from '../../../components/common/ImageViewer'
import TransactionCard from '../../transaction/TransactionDetail/TransactionCard'
import Text from '../../../components/common/Text'
import { useGetGiveawayById, useGetGiveaways } from '../../../services/giveaway/giveaway-query'
import dayjs from 'dayjs'

const TextLib = styled(Typography)`
  line-height: 2 !important;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(TextLib)<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`
const RewardDetail = () => {
  const { giveawayId } = useRouter().query

  const { data: giveaway } = useGetGiveawayById(giveawayId)
  const {
    user,
    transactionId,
    transaction,
    campaignName,
    prefix,
    unit,
    status,
    amount,
    createdAt,
    creatorId,
    userId,
    errorMessage,
  } = giveaway || {}
  const portData = useMemo(
    () => [
      {
        label: 'Campaign Name',
        value: campaignName || '-',
      },
      {
        label: 'Prefix',
        value: prefix || '-',
      },
      {
        label: 'Amount',
        value: `${amount}` || '-',
      },
      {
        label: 'Unit ',
        value: unit || '-',
      },
      { label: 'Status', value: status || '-' },
      { label: 'createdAt', value: dayjs(createdAt).format('D MMMM YYYY HH:mm') },
      { label: 'creatorId', value: creatorId || '-' },
      { label: 'userId', value: userId || '-' },
      { label: 'errorMessage', value: errorMessage || '-' },
    ],
    [amount, campaignName, createdAt, creatorId, errorMessage, prefix, status, unit, userId],
  )

  const fullName = useMemo(() => {
    return `${user?.firstnameTh} ${user?.lastnameTh}`
  }, [user])

  return (
    <DetailPage
      title={
        <Flex>
          <TextColor variant="h5">Campaign</TextColor>
        </Flex>
      }
      contents={portData}
      gridItems={[
        <UserCard userId={giveaway?.userId || ''} />,
        transactionId ? (
          <TransactionCard portId={transaction?.portId} fullname={fullName} />
        ) : (
          <></>
        ),
      ]}
    />
  )
}

export default RewardDetail
