import React, { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useIsFetching } from 'react-query'
import { useLogin } from '../../services/auth/auth-query'
import { cp, createValidation, required, email } from '../../../utils/field-validation'
import NolabelInput from '../../components/common/NoLabelInput'
import OTPModal from '../../components/common/OTPModal'
import { useOtpNumberByEmail } from '../../services/otp/otp-query'
import { OtpAction } from '../../services/otp/otp-types'

const initialvalues = {
  email: 'admin@moveplus.com',
  password: 'admin1234',
}
export type LoginFormValue = {
  email?: string
  password?: string
}

type OTPState = {
  id: string
  refCode: string
}

const loginValidation = createValidation<LoginFormValue>(() => ({
  email: cp(required('required  email'), email('Invalid email')),
  password: cp(required('required  password')),
}))

export default () => {
  const isFetching = useIsFetching()
  const { t } = useTranslation()
  const { mutateAsync } = useLogin() // diable default error
  const { mutateAsync: requestOtp } = useOtpNumberByEmail()
  const [errorMessage, setErrorMessage] = useState('')
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [otpModalVisible, setOtpModalVisible] = useState(false)
  const [otpState, setOtpState] = useState<OTPState | null>(null)
  const [loginData, setLoginData] = useState<LoginFormValue | null>(null)

  const handleOTPConfirm = useCallback(
    async (otp: string) => {
      try {
        const updatedLoginData = { ...loginData, otp, otpId: otpState?.id }
        await mutateAsync(updatedLoginData)
        setOtpModalVisible(false)
      } catch (error) {
        setOtpErrorMessage(t(error))
      }
    },
    [mutateAsync, loginData, t, otpState],
  )
  // const onSubmit = useCallback(
  //   async value => {
  //     try {
  //       await mutateAsync(value)
  //     } catch (error) {
  //       setErrorMessage(t(error))
  //     }
  //   },
  //   [mutateAsync, t],
  // )

  const onSubmit = useCallback(
    async (value: LoginFormValue) => {
      try {
        const otpResponse = await requestOtp({
          action: OtpAction.INTERNAL_LOGIN,
          value: value.email,
        })

        setOtpState({ id: otpResponse.otpId, refCode: otpResponse.refCode }) // Assuming otpResponse contains an otpRef
        setLoginData(value)
        setOtpModalVisible(true)
        setOtpErrorMessage('') // Reset OTP error message
      } catch (error) {
        setErrorMessage(t(error))
      }
    },
    [requestOtp, t],
  )
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Login Account</h3>
        <p className="text-muted font-weight-bold">Enter your username and password</p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <Form
        initialValues={process.env.REACT_APP_MODE === 'develepment' ? initialvalues : undefined}
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        validate={loginValidation}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {errorMessage && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{errorMessage}</div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                <Field name="email" component={NolabelInput} placeholder="Email" />
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Field
                  name="password"
                  component={NolabelInput}
                  placeholder="Password"
                  type="password"
                />
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  Forgot Password
                </Link>
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                >
                  <span>Sign In</span>
                  {(submitting || !!isFetching) && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </form>
          )
        }}
      />
      <OTPModal
        show={otpModalVisible}
        handleClose={() => setOtpModalVisible(false)}
        handleConfirm={handleOTPConfirm}
        otpRef={otpState?.refCode}
        errorMessage={otpErrorMessage}
      />
    </div>
  )
}
