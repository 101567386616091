import React, { useMemo } from 'react'
import PortalCard from '../../../components/templates/PortalCard'
import Text from '../../../components/common/Text'
import { useGetTransactionByPortId } from '../../../services/transaction/transaction-query'
import numeral from 'numeral'
import {
  getColorTransactionStatus,
  getTransactionTypeColor,
  replaceUnderScoreAndCapitalize,
} from '../../../../utils/helper'
import * as paths from '../../../constants/path'
import { useGetPort } from '../../../services/port/port-query'

interface TransactionCardProps {
  portId?: string
  fullname?: string
}

export const TransactionCard = (props: TransactionCardProps) => {
  const { portId = '', fullname = '' } = props

  const { data: transaction, isLoading } = useGetTransactionByPortId(portId)
  const { status, type } = transaction || {}
  const { data: port } = useGetPort(portId)

  const dataTransaction = useMemo(() => {
    const typeTransaction = (
      <div>
        <Text bold style={{ color: getTransactionTypeColor(type), display: 'inline' }}>
          {replaceUnderScoreAndCapitalize(transaction?.type)}
        </Text>
        <Text bold style={{ color: getColorTransactionStatus(status), display: 'inline' }}>
          {` (${replaceUnderScoreAndCapitalize(status)})`}
        </Text>
      </div>
    )
    return [
      { label: 'Type', valueComponent: typeTransaction ? typeTransaction : '-' },
      { label: 'Rate', value: transaction?.sgRate ? `${transaction?.sgRate} THB/g.` : '-' },
      {
        label: 'Gold amount',
        value: transaction?.goldAmount
          ? `${numeral(transaction?.goldAmount).format('0,0.0000')} g.`
          : '-',
      },
      {
        label: 'Price',
        value: transaction?.price ? `${numeral(transaction?.price).format('0,0.00')} THB` : '-',
      },
      {
        label: 'Bank account',
        value:
          transaction?.bankAccountNo && transaction.bankName
            ? `${transaction?.bankAccountNo} - ${transaction?.bankName}`
            : '-',
      },
    ]
  }, [status, transaction, type])

  return !isLoading && portId && transaction ? (
    <PortalCard
      title={'Last Transaction'}
      contents={dataTransaction}
      viewMoreUrl={paths.transactions({
        queryParam: {
          q: port?.sgMobileNo,
          page: 1,
        },
      })}
    />
  ) : (
    <></>
  )
}

export default TransactionCard
