import React, { Fragment, ReactNode } from 'react'
import styled from '@emotion/styled'

import Text from '../common/Text'
import { Card } from 'react-bootstrap'
import { useMemo } from 'react'
import { useCallback } from 'react'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`
const RootLayout = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
`
const ContentDetailLayout = styled(Grid)`
  flex: 1;
  grid-template-columns: 150px auto;
  align-self: self-start;
`

export type ContentType = {
  label?: string
  value?: string | JSX.Element
  linkTo?: string
  component?: ReactNode
  position?: 'left' | 'right'
}

type DetailPageProps = {
  title: JSX.Element | string
  contents: ContentType[]
  gridItems?: JSX.Element[]
  extendLayout?: JSX.Element
  rightContent?: ReactNode
}

const DetailPage = (props: DetailPageProps) => {
  const { title, contents, gridItems, extendLayout, rightContent } = props

  const leftItems = useMemo(() => {
    return contents.filter(v => v.position === 'left' || !v.position)
  }, [contents])

  const rightItems = useMemo(() => {
    return contents.filter(v => v.position === 'right')
  }, [contents])

  const renderContent = useCallback((content: ContentType, index: number) => {
    const { label, value, linkTo, component } = content
    return component ? (
      <Fragment key={`${label!}${index}`}>{component}</Fragment>
    ) : (
      <Fragment key={`${label!}${index}`}>
        <Text>{label}</Text>
        {typeof value === 'string' || value === undefined || value === null ? (
          <Text
            bold
            linkTo={linkTo}
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
            }}
          >
            {value}
          </Text>
        ) : (
          value
        )}
      </Fragment>
    )
  }, [])

  return (
    <RootLayout>
      <Card style={{ gridColumn: '1 / -1' }}>
        <Card.Body>
          <div>
            {typeof title === 'string' ? (
              <Text variant="h5" bold>
                {title}
              </Text>
            ) : (
              title
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ContentDetailLayout style={{ paddingRight: '30px' }}>
              {leftItems.map(renderContent)}
            </ContentDetailLayout>
            {rightContent ? (
              rightContent
            ) : (
              <ContentDetailLayout>{rightItems.map(renderContent)}</ContentDetailLayout>
            )}
          </div>
          {extendLayout ? extendLayout : null}
        </Card.Body>
      </Card>
      {gridItems?.map((gridItem, index) => (
        <Fragment key={`${index}`}>{gridItem}</Fragment>
      ))}
    </RootLayout>
  )
}

export default DetailPage
