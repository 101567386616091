import React, { useCallback } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { Link } from 'react-router-dom'

import NoLabelInput from '../../../components/common/NoLabelInput'
import { createValidation, required, cp, equal } from '../../../../utils/field-validation'

export type PasswordFormValue = {
  email: string
  password: string
  confirmPassword: string
  otpId: string
  otp: string
}

export const validation = createValidation<PasswordFormValue>(value => ({
  password: required('Enter your password'),
  confirmPassword: cp(
    required('Enter your password'),
    equal(value.password, 'Passwords do not match'),
  ),
}))

export default (props: FormRenderProps<any> & { errorMessage: string; title: string }) => {
  const { submitting, errorMessage, title, invalid } = props
  const validateWeakPassword = useCallback((value: string) => {
    const regStrongPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    if (value?.length && value?.length < 8) {
      return `Password should have at least 8 charecters`
    }
    if (value?.length && !regStrongPassword.test(value)) {
      return `Password much contain at least 1 upper case letter and contain at least 1 lower case letter or and contain at least 1 number or special character`
    }
    return undefined
  }, [])
  return (
    <>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">{title}</h3>
        <p className="text-muted font-weight-bold">
          Enter your details to change password your for account
        </p>
      </div>
      {errorMessage && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{errorMessage}</div>
        </div>
      )}

      <div className="form-group fv-plugins-icon-container">
        <Field
          placeholder="New Password"
          type="password"
          name="password"
          component={NoLabelInput}
          validate={validateWeakPassword}
        />
      </div>
      <div className="form-group fv-plugins-icon-container">
        <Field
          placeholder="Confirm New Password"
          name="confirmPassword"
          type="password"
          component={NoLabelInput}
        />
      </div>
      <div className="form-group d-flex flex-wrap flex-center">
        <button
          type="submit"
          disabled={invalid}
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        >
          <span>Submit</span>
          {/* {submitting && <span className="ml-3 spinner spinner-white"></span>} */}
        </button>

        <Link to="/auth/login">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Cancel
          </button>
        </Link>
      </div>
    </>
  )
}
