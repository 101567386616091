import { useMutation } from 'react-query'
import { api } from '../../../utils/api'
import { PasswordFormValue } from '../../pages/auth/shared/PasswordForm'
import { OtpFormValue } from '../../pages/auth/shared/OtpForm'
import { CreateOTPMobileByEmailBoDto, CreateOTPMobileByTokenBoDto, Otp } from './otp-types'

export const OTP_URL = 'internal/otp'
export const OTP_EMAIL = `${OTP_URL}/email`
export const OTP_MOBILE_BY_EMAIL = `${OTP_URL}/mobile-by-email`
export const OTP_MOBILE_BY_TOKEN = `${OTP_URL}/mobile-by-token`
export const VERIFY_OTP = `${OTP_URL}/verify`

export const useOtpEmail = () => {
  return useMutation<
    Otp,
    unknown,
    Pick<PasswordFormValue, 'email'> & { action: 'forgot-password' }
  >(
    async params => {
      const response = await api.savvyGold.post<Otp>(OTP_EMAIL, {
        value: params.email,
        action: params.action,
      })
      return response.data.data
    },
    {
      onError: () => {},
    },
  )
}

export const useOtpNumberByEmail = () => {
  return useMutation<Otp, unknown, CreateOTPMobileByEmailBoDto>(
    async params => {
      const response = await api.savvyGold.post<Otp>(OTP_MOBILE_BY_EMAIL, {
        value: params?.value,
        action: params?.action,
      })
      return response.data.data
    },
    {
      onError: () => {},
    },
  )
}

export const useOtpMobileByToken = () => {
  return useMutation<Otp, unknown, CreateOTPMobileByTokenBoDto>(
    async params => {
      const response = await api.savvyGold.post<Otp>(OTP_MOBILE_BY_TOKEN, {
        action: params?.action,
      })
      return response.data.data
    },
    {
      onError: () => {},
    },
  )
}

export const useVerifyOtp = () => {
  return useMutation<unknown, unknown, OtpFormValue & { otpId: string }>(
    params => {
      return api.savvyGold.post(VERIFY_OTP, params)
    },
    {
      onError: () => {},
    },
  )
}
