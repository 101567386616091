import React, { CSSProperties, Fragment, ReactNode } from 'react'
import { Card } from 'react-bootstrap'
import styled from '@emotion/styled'
import Text from '../common/Text'
import { Link } from 'react-router-dom'

export type IPortalCardContentData = {
  label: string
  value?: string | number | undefined | ReactNode
  valueComponent?: ReactNode
  linkTo?: string
  valueTextColor?: string
}

type PortalCardProps = {
  title: JSX.Element | string
  contents: IPortalCardContentData[]
  viewMoreUrl?: string
  style?: CSSProperties
}

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(100px, 150px) minmax(150px, 1fr);
`
const TopLayout = styled.div`
  display: flex;

  align-items: center;
`

export default (props: PortalCardProps) => {
  const { title, contents, viewMoreUrl, style } = props
  return (
    <Card style={{ display: 'inline-flex', ...style }}>
      <Card.Body>
        <TopLayout>
          <Text bold variant="h5">
            {title}
          </Text>
          {viewMoreUrl && (
            <Link to={viewMoreUrl}>
              <Text style={{ paddingLeft: '10px' }}>(view more)</Text>
            </Link>
          )}
        </TopLayout>
        <Grid>
          {contents.map(
            (
              { label, value, linkTo, valueTextColor = '#000000', valueComponent },
              contentIndex,
            ) => {
              if (linkTo) {
                return (
                  <Fragment key={`${contentIndex}-${label}`}>
                    <Text>{label}</Text>
                    <Link
                      to={linkTo}
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      <Text bold>{value}</Text>
                    </Link>
                  </Fragment>
                )
              }
              if (valueComponent) {
                return (
                  <Fragment key={`${contentIndex}-${label}`}>
                    <Text>{label}</Text>
                    {valueComponent}
                  </Fragment>
                )
              } else {
                return (
                  <Fragment key={`${contentIndex}-${label}`}>
                    <Text>{label}</Text>
                    <Text
                      bold
                      style={{
                        color: valueTextColor,
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      {value}
                    </Text>
                  </Fragment>
                )
              }
            },
          )}
        </Grid>
      </Card.Body>
    </Card>
  )
}
