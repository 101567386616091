import React, { ComponentType, useCallback } from 'react'
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format'
import Input from './Input'
import styled from '@emotion/styled'

const NumericFormatWrapper = styled.div`
  .MuiTextField-root {
    width: 100%;
    margin: 0;
  }
`

export interface INumberInputProps
  extends Omit<
    NumericFormatProps,
    'value' | 'onChange' | 'onValueChange' | 'customInput' | 'defaultValue' | 'size'
  > {
  value?: number
  onChange?: (value?: number) => void
  thousandSeparator?: boolean
}

// https://s-yadav.github.io/react-number-format/docs/numeric_format
export const NumberInput = (props: INumberInputProps) => {
  const { value, onChange, thousandSeparator = true, style, disabled, ...restProps } = props

  const handleChange = useCallback(
    (values: NumberFormatValues) => {
      onChange?.(values.floatValue)
    },
    [onChange],
  )

  return (
    <NumericFormatWrapper>
      <NumericFormat
        value={value}
        onValueChange={handleChange}
        customInput={Input as ComponentType}
        decimalScale={2}
        thousandSeparator={thousandSeparator}
        {...restProps}
      />
    </NumericFormatWrapper>
  )
}
