import React from 'react'
import { makeField } from './tools'
import Select, { SelectProps } from '../common/Select'
import Input from '../common/Input'
import DataPiker from '../common/DataPiker'
import CheckBox from '../common/CheckBox'
import { Field, FieldProps } from 'react-final-form'
import DateRangePicker, { DateRangePickerProps } from '../common/DateRangePicker'
import Switch from '../common/Switch'
import { INumberInputProps, NumberInput } from '../common/NumberInput'

export const InputField = makeField(Input)
export const DatePickerField = makeField(DataPiker)
export const CheckBoxField = makeField(CheckBox)
export const SwitchField = makeField(Switch)

type DateRangePickerFieldProps = FieldProps<any, any, any> & DateRangePickerProps
export const DateRangePickerField = (props: DateRangePickerFieldProps) => {
  const { name } = props
  return <Field {...props} name={name} render={makeField(DateRangePicker)} />
}

export const SelectField = (props: FieldProps<any, any, any> & SelectProps<BaseOptionType>) => {
  const { name } = props
  return <Field {...props} name={name} render={makeField(Select)} />
}

export const NumberInputField = (props: FieldProps<any, any, any> & INumberInputProps) => {
  const { name } = props
  return <Field {...props} name={name} render={makeField(NumberInput)} />
}
