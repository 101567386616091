const windowEnv = (window as any)?._env_ || {}

const parseBoolean = (e?: string | boolean) => e === true || e === 'true'
const parseString = (e?: string) => e || ''

export const appConfig = {
  API_HOST: parseString(windowEnv.apiHost),
  APP_NAME: parseString(windowEnv.appName),
  ENABLE_FEATURE_ARTICLE: parseBoolean(windowEnv.enableFeatureArticle),
  ENABLE_FEATURE_APPROVE_KYC: parseBoolean(windowEnv.enableFeatureApproveKyc),
  ENABLE_FEATURE_SELL_MANUAL: parseBoolean(windowEnv.enableFeatureSellManual),
  ENABLE_FEATURE_TRUE_MONEY: parseBoolean(windowEnv.enableFeatureTrueMoney),
  ENABLE_FEATURE_GIVEAWAY: parseBoolean(windowEnv.enableFeatureGiveaway),
  ENABLE_FEATURE_SELL_TO_BANK: parseBoolean(windowEnv.enableFeatureSellToBank),
  ENABLE_FEATURE_SELL_TO_TMN: parseBoolean(windowEnv.enableFeatureSellToTMN),
  ENABLE_FEATURE_SELL_LIMIT: parseBoolean(windowEnv.enableFeatureSellLimit),
}
